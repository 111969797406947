@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body{
    ::-webkit-scrollbar {
        width: 10px; 
    }
    ::-webkit-scrollbar-track {
        background-color: transparent; 
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 6px; 
    }

    scrollbar-width: thin;
    scrollbar-color:  #d1d3d4 transparent;
}

.PhoneInputInput {
    border: none;
    background-color: transparent;
    outline: none;
}

.PhoneInputInput:focus {
    outline: none;
}