.selected-flag .open {
    background-color: black;
}

.react-tel-input .flag-dropdown.open .selected-flag{
    background-color: transparent;
}

/* .react-tel-input .selected-flag{
    border-right: 1px solid red;
} */

.react-tel-input .selected-flag .flag{
    background-color: rgb(96, 96, 96);
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background-color: transparent;

}

.react-tel-input .country-list .country.highlight{
    background-color: #878a8a;
}

.react-tel-input .country-list .country:hover{
    background-color: #b5b9b9;

}

.react-tel-input .country-list .search{
    background-color: #b5b9b9;
    display: flex;
    border-radius: 10px;
    padding-right: 10px;
}

.react-tel-input .country-list .search-box {
    width: 100%;

}

.react-tel-input .selected-flag .arrow{
    border-top: 6px solid #1DA069;
}

